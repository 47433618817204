import * as React from "react";
import { Link, graphql } from "gatsby";

import Layout from "../../components/layout";

const pageTitle = "category list";

const Category = ({ data }) => {
  return (
    <Layout pageTitle={pageTitle}>
      <ul>
        {data.allMicrocmsNewsCategory.nodes.map((node) => {
          const { name, newsCategoryId, slug } = node;
          return (
            <Link to={`/news/category/${slug}`}>
              <li key={newsCategoryId}>{name}</li>
            </Link>
          );
        })}
      </ul>
      <div>
        <Link to="/news">News一覧</Link>
      </div>
    </Layout>
  );
};

export default Category;

export const query = graphql`
  query {
    allMicrocmsNewsCategory {
      nodes {
        name
        slug
        newsCategoryId
      }
    }
  }
`;
